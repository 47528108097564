import { DrupalNode } from "next-drupal";
import CardImplants from "./CardImplants";
import CardArticle from "./CardArticle";
import CardComplete from "./CardComplete";

export interface CardProps {
  variant?: CardVariantModel;
  node: DrupalNode
}

export type CardVariantModel = "default" | "article" | "implants" | "complete"

export const Card: React.FC<CardProps> = ({variant, ...otherProps}) => {
  
  const layoutVariants = {
    default: CardArticle,
    article: CardArticle,
    complete: CardComplete,
    implants:  CardImplants,
  }

  const Component = layoutVariants[variant] || CardImplants;
  return <Component {...otherProps} />;
}

export default Card