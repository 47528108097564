import EastIcon from '@mui/icons-material/East';
import Box, { BoxProps } from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import useTheme from "@mui/system/useTheme";
import { theme } from "@styles/mui/theme";
import { PLACEHOLDER_IMAGE, getThumbnailAlt, getThumbnailUrl } from "@helper/media";
import { DrupalNode } from "next-drupal";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import Link from "next/link";
import { FC } from "react";

type Props = {
  node: DrupalNode;
  isMobile?: boolean;
  href?: string;
};

const CardContent: FC<Props & BoxProps> = ({ node, isMobile, href, sx }) => {
  const { t } = useTranslation();
  const categoryColor = theme.palette.secondary.main;
  const image = node?.field_article_image ? getThumbnailUrl(node?.field_article_image) : PLACEHOLDER_IMAGE[node.type];
  
  return (
    <Box
      className="article-card-content"
      sx={{
        userSelect: "none",
        backgroundColor: categoryColor,
        width: "100%",
        paddingBottom: "100%",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-end",
        transition: "box-shadow 0.25s ease-in-out",
        "&:hover": {
          boxShadow: isMobile
            ? ""
            : "0px 8px 18px -6px rgba(0, 26, 112, 0.4), 0px 12px 42px -4px rgba(0, 26, 112, 0.4)",
        },
        ...sx,
      }}
    >
      <Box
        sx={{
          overflow: "hidden",
          position: "absolute",
          top: "0",
          width: "100%",
          height: "100%",
        }}
      >
        {image && (
          <Image
            style={{ objectFit: "cover", zIndex: "0", userSelect: "none" }}
            src={image}
            alt={getThumbnailAlt(node.field_article_image)}
            fill
            sizes="(max-width: 1024px) 100vw, 33vw"
            quality={100}
          />
        )}

          {node?.field_label && (
            <Box
              sx={{
                zIndex: "2",
                position: "absolute",
                top: 8,
                left: 0,
                backgroundColor: theme.palette.common.white,
                py: 4,
                px: 24
              }}>
              <Typography variant="overline" color={theme.palette.common.black}>
                {node?.field_label}
              </Typography>
            </Box>
          )}

        <Box
          className="main"
          sx={{
            zIndex: "2",
            position: "absolute",
            top: "0",
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            alignContent: "center",
            color: "#fff",
            pt: 8,
            pb: 54,
            textAlign: "center",
          }}
        >
          

          <Typography variant="subtitle">
            <strong>{node?.title}</strong>
          </Typography>

          <Box height={24} mt={12}>
            {!href || !node.field_disabled && <Button variant="link" startIcon={<EastIcon color="inherit" />} >{t("discoverMore")}</Button>}
          </Box>
        </Box>

        <Box
          className="bg"
          sx={{
            zIndex: "1",
            width: "100%",
            height: "100%",
            position: "absolute",
            top: "0",
            left: "0",
            opacity: "0.8",
            background: `linear-gradient(0deg, ${categoryColor} 0%, rgba(255,255,255,0) 100%)`,
          }}
        />
      </Box>
    </Box>
  );
};

const CardImplants: FC<Props & BoxProps> = ({ node, sx }) => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down("lg"));
  const href = node?.href || node?.path?.alias?.replace("/news/", "/next-journal/");
  const isDisabled: boolean = !href || node.field_disabled

  return (
      <Box
        component={isDisabled ? 'div' : Link}
        {...!isDisabled ? { href, passHref: true } : {}}
        sx={isMobile ? { marginBottom: "4rem" } : {}}
      >
        <CardContent sx={sx} node={node} isMobile={isMobile} href={href} />
      </Box>
  );
};

export default CardImplants;
