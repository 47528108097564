import { DrupalLinkField } from "@model/fields.model";
import parse, { HTMLReactParserOptions } from "html-react-parser";
import { sanitize } from "isomorphic-dompurify";

export const removeHtmlTags = (input: string): string => {
  return input?.replace(/<\/?[^>]+(>|$)/g, '');
};

export const removeEmojis = (input: string) => {
  return input?.replace(/\p{Emoji}\s?/gu, '');
};

export const cutString = (string, maxLength = 50) => {
  string = string ? string?.toString().replace(/<img.*?\/>/g, '') : null;
  const stringMod = string ? (string?.length <= maxLength ? string : `${string?.substring(0, maxLength)}...`) : null;
  return stringMod;
};

export const resolveLink = (link: DrupalLinkField): string => {
  if (!link?.uri || !link?.url) {
    return null
  }

  if(link.uri.startsWith('internal:/')) {
    return link.uri.replace('internal:/', '')
  }

  return link.url
} 

export const parseHTML = (html: string, options?: HTMLReactParserOptions) => {
  if (!html) return;

  return parse(
    sanitize(html, {
      ADD_TAGS: ["iframe"],
      ADD_ATTR: [
        "allow",
        "allowfullscreen",
        "frameborder",
        "scrolling",
        "target",
        "href",
      ],
    }),
    options
  );
};