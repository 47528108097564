import useMediaQuery from '@mui/material/useMediaQuery';
import { theme } from '@styles/mui/theme';
import { motion } from 'framer-motion';
import { PropsWithChildren } from 'react';

interface FadeInProps extends PropsWithChildren {
  once?: boolean;
  [key: string]: any;
}

const FadeIn = ({ children, once = true, ...props }: FadeInProps) => {
  const isMobile = !useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <motion.div
      initial={{ opacity: 0 }}
      transition={{ duration: 1, delay: 0.1 }}
      viewport={{
        once,
        margin: isMobile ? '0px 0px -50px 0px' : '0px 0px -200px 0px',
      }}
      whileInView={{ opacity: 1, y: 0 }}
      {...props}
    >
      {children}
    </motion.div>
  );
};

export default FadeIn;
