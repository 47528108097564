import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { FC } from "react";

type Props = { isMobile: boolean; title: string };

const ContentHeader: FC<Props> = ({ isMobile, title }) => {
  return (
    <>
      <Box
        sx={{
          width: "5rem",
          height: "0.5rem",
          background: "linear-gradient(90deg, #1E3591 28.65%, #72C242 100%)",
          marginBottom: "0.75rem",
          borderRadius: "0 4px 0 0",
        }}
      />

      <Typography
        sx={{
          textTransform: "Capitalize",
          color: "#001a70",
          fontWeight: "400",
          transition: "none",
          fontSize: isMobile ? "1.75rem" : "2.25rem",
          lineHeight: isMobile ? "2.25rem" : "3rem",
          marginBottom: isMobile ? 20 : 40,
        }}
      >
        {title}
      </Typography>
    </>
  );
};

export default ContentHeader;