import Box from "@mui/material/Box";
import Card, { CardVariantModel } from "@components/Modules/Cards";
import FadeIn from "@components/Common/FadeIn/FadeIn";
import { DrupalNode } from "next-drupal";
import { FC } from "react";

type Props = {
  articles: DrupalNode[];
  columns?: number;
  cardVariant?: CardVariantModel;
};

const ArticleCardGrid: FC<Props> = ({ articles, columns = 3, cardVariant = "default" }) => {

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: { xs: "1fr", lg: `repeat(${columns}, 1fr)`},
        rowGap: { xs: 30 },
        columnGap: "2%",
        flexWrap: "wrap",
        justifyContent: "space-between",
        alignItems: "center",
        paddingBottom: 80,
      }}
    >
      {articles?.map((article) => (
        <FadeIn key={article?.id}>
          <Card variant={cardVariant}  node={article} />
        </FadeIn>
      ))}
    </Box>
  );
};

export default ArticleCardGrid;
