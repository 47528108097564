import { useState, useEffect, MutableRefObject  } from "react";

export const useOnScreen = (ref: MutableRefObject<Element>, rootMargin = "0px") => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      {
        rootMargin
      }
    );

    const currentElement = ref?.current;

    if (!currentElement) return;

    observer.observe(currentElement);

    return () => {
      observer.unobserve(currentElement);
    };
  }, [ref, rootMargin]);

  return isVisible;
};

export default useOnScreen